import { messageData } from './messageData'
import {trim} from "core-js/internals/string-trim";
export default (property) => {

    property.refreshTokenRate = 5 * 60 * 1000
    property.refreshToken = function () {
        let refreshToken = sessionStorage.getItem('refreshToken')
        let accessToken = sessionStorage.getItem('accessToken')

        if (!refreshToken) {
            return
        }
        let formData = new FormData()
        formData.append('refreshToken', refreshToken)

        this.$post(this.$USER_REFRESH_TOKEN, 'refreshToken', formData, false, (result) => {
            if (accessToken !== result.data[0].accessToken) {
                sessionStorage.setItem('accessToken', result.data[0].accessToken)
                sessionStorage.setItem('refreshToken', result.data[0].refreshToken)
            }

            setTimeout(() => {
                this.refreshToken()
            }, this.refreshTokenRate)
        }, (result) => {
            this.$notify({type: 'error', text: result.message})
            this.$router.push({name: 'Login'}).catch(() => {
            })
        })
    }

    property.numberWithCommasAndDecimal = function (x) {
        let decimalView = sessionStorage.getItem('decimalView')
        if (!decimalView) {
            decimalView = 9
        }
        let zeros = ''
        for (let i = 0; i < decimalView; i++) {
            zeros += '0'
        }

        if (!(x + '').includes('.')) {
            return (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + zeros
        } else {
            let parts = x.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            parts[1] = parts[1] + zeros
            parts[1] = parts[1].substr(0, decimalView)
            return parts.join('.')
        }
    }

    property.setDecimal = function (n, decimal) {
        let result = Math.floor(n * decimal) / decimal
        return result
    }

    property.numberWithCommas = function (x) {
        if (!(x + '').includes('.')) {
            return (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return `${((x + '').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(x + '').split('.')[1]}`
        }
    }

    property.getUserProfile = function (callback) {
        this.$get(this.$USER_PROFILE, 'GetUserProfile' + Math.random(), true, (result) => {
            callback({success: true, userProfile: result.data[0]})
        }, (result) => {
            callback({success: false, userProfile: result})
        })
    }

    property.formatDate = function (timeStamp, dateType = 'full') {
        function makeTwoDigits(number) {
            return number >= 10 ? number : '0' + number
        }

        let d = new Date(timeStamp)
        let year = d.getFullYear()
        let month = makeTwoDigits(d.getMonth() + 1)
        let date = makeTwoDigits(d.getDate())
        let hour = makeTwoDigits(d.getHours())
        let min = makeTwoDigits(d.getMinutes())
        let sec = makeTwoDigits(d.getSeconds())
        if (dateType === 'full') {
            return `${year}-${month}-${date} ${hour}:${min}:${sec}`
        } else if (dateType === 'date') {
            return `${year}-${month}-${date}`
        } else if (dateType === 'month') {
            return `${year}. ${month}`
        } else {
            return `${hour}:${min}:${sec}`
        }
    }

    property.httpAlert = function (type, data) {
        const locale = localStorage.getItem('locale')

        if(locale === 'en') {
            if(type === 'success') {
                this.$notify({type: 'success', 'text': messageData[data.message]})
            } else if(type === 'error') {
                if (data.error[0]) {
                    this.$notify({type: 'error', 'text': messageData[data.error[0].message]})
                } else {
                    this.$notify({type: 'error', 'text': messageData[data.message]})
                }
            }

        } else {
            if(type === 'success') {
                this.$notify({type: 'success', 'text': data.message})
            } else if(type === 'error') {
                if (data.error[0]) {
                    this.$notify({type: 'error', 'text': data.error[0].message})
                } else {
                    this.$notify({type: 'error', 'text': data.message})
                }
            }

        }

    }

    property.httpError = function (data) {
        console.log(data.message)
        console.log(data.error[0])
        const locale = localStorage.getItem('locale')
        if(locale === 'en') {
            if (data.error[0]) {
                if(data.error[0].message.indexOf('이상의 수량을 입력해 주세요') !== -1){
                    const amount = trim(data.error[0].message.slice(0,data.error[0].message.indexOf('이상의 수량을 입력해 주세요')))
                    this.$notify({type: 'error', 'text': 'Please enter a quantity more than ' + amount + '.'})
                } else {
                    this.$notify({type: 'error', 'text': messageData[data.error[0].message]})
                }
            } else {
                if(data.message.indexOf('이상의 수량을 입력해 주세요') !== -1){
                    const amount = trim(data.message.slice(0,data.message.indexOf('이상의 수량을 입력해 주세요')))
                    this.$notify({type: 'error', 'text': 'Please enter a quantity more than ' + amount + '.'})
                } else {
                    this.$notify({type: 'error', 'text': messageData[data.message]})
                }
            }
        } else {
            if (data.error[0]) {
                this.$notify({type: 'error', 'text': data.error[0].message})
            } else {
                this.$notify({type: 'error', 'text': data.message})
            }
        }

    }

    property.closePu = function (e) {
        if (e.target.classList.contains('remove')) {
            this.show = false
            this.$emit('closePopup')
        }
    }

    property.dateFormat = function (date) {
        let dateFormat = date.getFullYear() + "-" + ((date.getMonth() + 1) > 9
                ? (date.getMonth() + 1).toString() : "0" + (date.getMonth() + 1)) +
            "-" + (date.getDate() > 9 ? date.getDate().toString() : "0" + date.getDate().toString());
        return dateFormat
    }

    property.window = window
    property.$EVENT_QR_STRING = "$EVENT_QR_STRING"
    property.openQRReader = (_type) => {
        console.log(_type)
    }
    property.setQRString = (_qrstr = '') => {
        var event = new CustomEvent('$EVENT_QR_STRING', {'detail': _qrstr});
        window.dispatchEvent(event);
    }

}
