<template>
  <div class="wrap">
    <div class="header clear">
      <div class="left" @click="$router.back()">
        <button><i class="icon-arrow-left"></i></button>
      </div>
    </div>
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">
            <div class="tit_area">
              <h2 class="title">{{ $t('pin.reset') }}</h2>
            </div>
            <div class="content_area">
              <div class="text_field group">
                <div class="basic_input">
                  <input type="password" :placeholder="$t('pin.currentNumber')" maxlength="6" v-model="pin" inputmode="numeric">
                </div>
                <div class="basic_input">
                  <input type="password" :placeholder="$t('pin.newNumber')" maxlength="6" v-model="newPin" inputmode="numeric">
                </div>
                <div class="basic_input">
                  <input type="password" :placeholder="$t('pin.checkNewNumber')" maxlength="6" v-model="newPinConfirm"
                         inputmode="numeric">
                </div>
              </div>
              <div class="group">
                <p class="info_desc">{{ $t('pin.changePinText') }}</p>
              </div>
              <div class="text_field group">
                <div class="basic_input">
                  <input type="password" :placeholder="$t('input.checkPassword')" v-model="password"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="changePin">{{ $t('pin.resetPin') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypagePinReset",

  data() {
    return {
      pin: '',
      newPin: '',
      newPinConfirm: '',
      password: '',
    }
  },

  created() {
    console.log()
  },

  methods: {
    changePin() {
      if (!this.pin || !this.newPin || !this.newPinConfirm || !this.password) {
        this.$notify({type: 'error', text: this.$t('message.inputNull')})
        return
      }

      if (this.newPin !== this.newPinConfirm) {
        this.$notify({type: 'error', text: this.$t('message.checkPin')})
        return
      }

      let formData = new FormData()
      formData.append('pin', this.pin)
      formData.append('newPin', this.newPin)
      formData.append('password', this.password)
      this.$post(this.$MYPAGE_PIN_RESET, 'postMypagePinReset', formData, true, (result) => {
        this.httpAlert('success', result)
        this.$router.back()
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>