export const messageData = {
    '데이터 삭제가 실패했습니다.': 'Data deletion has failed.',
    '입력하신 값 중에서 기존에 입력한 데이터와 중복이 있습니다.': 'There is a duplication among the values you entered and the existing data.',
    '데이터 조회가 실패했습니다.': 'Data retrieval has failed.',
    '서버가 혼잡합니다. 잠시 후 다시 이용해주세요.': 'The server is congested. Please try again later.',
    '잔고가 부족합니다.': 'Insufficient balance.',
    '필수 항목의 값을 입력해주세요.': 'Please enter values for required fields.',
    '데이터 저장이 실패했습니다.': 'Data storage has failed.',
    '데이터 갱신이 실패했습니다.': 'Data update has failed.',
    '이미 등록된 상태입니다.': 'It is already registered.',
    '숫자만 입력가능합니다.': 'Only numbers are allowed.',
    '양수만 입력가능합니다.': 'Only positive numbers are allowed.',
    '소수점 자리수를 확인해 주세요.': 'Please check the decimal places.',
    '다시시도해주세요.': 'Please try again.',
    '요청에 실패했습니다.': 'Request failed.',
    '요청에 성공했습니다.': 'Request was successful.',
    '요청 중 오류가 발생했습니다.': 'An error occurred during the request.',
    '아이디 또는 비밀번호가 틀렸습니다.': 'The ID or password is incorrect.',
    '지원되지않는 인증방식입니다.': 'Unsupported authentication method.',
    '잘못된 사용자 정보입니다.': 'Incorrect user information.',
    '비밀번호가 일치하지 않습니다.': 'The password does not match.',
    '비밀번호는 최소 8자이상, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자가 포함되어야 합니다.': 'The password must be at least 8 characters long and contain at least one letter, one number, and one special character.',
    '이메일을 입력해주세요.': 'Please enter your email.',
    '휴대폰번호를 입력해주세요.': 'Please enter your phone number.',
    '핀번호를 입력해주세요.': 'Please enter your PIN.',
    '핀번호가 일치하지 않습니다.': 'The PIN does not match.',
    '등록이 필요한 서비스입니다.': 'Registration is required for this service.',
    'OTP 코드를 입력해 주세요.': 'Please enter the OTP code.',
    'OTP 코드가 일치하지 않습니다.': 'The OTP code does not match.',
    '이미 존재하는 이메일입니다.': 'The email already exists.',
    '잘못된 코드입니다.': 'Invalid code.',
    '잘못된 핀번호입니다.': 'Invalid PIN number.',
    '이미 인증번호를 전송하였습니다.': 'The verification code has already been sent.',
    '출금이 정지된 사용자입니다.': 'This user is suspended from withdrawals.',
    '잘못된 데이터입니다.': 'Invalid data.',
    '잘못된 토큰입니다.': 'Invalid token.',
    '입력사항을 입력해주세요.': 'Please enter the required information.',
    '이중인증을 등록해주세요.': 'Please register for two-factor authentication.',
    '성공적으로 등록하였습니다.': 'Successfully registered.',
    '인증을 다시진행해주세요.': 'Please proceed with the authentication again.',
    '잘못된 정보를 입력하셨습니다.': 'You entered incorrect information.',
    '이미 실행 중입니다. 잠시만 기다려 주십시오.': 'It is already running. Please wait a moment.',
    '사용자 IP 조회에 실패했습니다.': 'Failed to retrieve user IP.',
    '허용되지 않는 IP 입니다.': 'This IP is not allowed.',
    '암복호화 기본 셋팅에 실패했습니다.': 'Encryption/decryption basic setting failed.',
    '암호화에 실패했습니다.': 'Encryption failed.',
    '복호화에 실패했습니다.': 'Decryption failed.',
    '잘못된 형식의 데이터 입니다.': 'The data format is incorrect.',
    '사용자 아이디를 입력해 주세요.': 'Please enter the user ID.',
    '사용자 키를 입력해 주세요.': 'Please enter the user key.',
    '요청 시간을 입력해 주세요.': 'Please enter the request time.',
    '만료된 요청입니다.': 'The request has expired.',
    '요청 타입을 입력해주세요.': 'Please enter the request type.',
    '요청 수량을 입력해주세요.': 'Please enter the request quantity.',
    '증감 여부를 입력해주세요.': 'Please enter the increase/decrease status.',
    '현재 중복 참여가 불가능한 스테이킹에 참여중입니다. 진행중인 스테이킹을 완료해주세요.': 'Currently participating in a stacking that doesn\'t allow duplicates. Please complete the ongoing stacking.',
    '스테이킹 플랜 고유 값을 입력해 주세요.': 'Please enter the unique value of the stacking plan.',
    '스테이킹 플랜 고유 값은 양수로 입력해 주세요.': 'The stacking plan unique value should be entered as a positive number.',
    '스테이킹 고유 값을 입력해 주세요.': 'Please enter the unique value of the stacking.',
    '스테이킹 수량을 입력해 주세요.': 'Please enter the stacking quantity.',
    '스테이킹 수량은 양수로 입력해 주세요.': 'The stacking quantity should be entered as a positive number.',
    '스테이킹 수량을 최소 수량 보다 크게 입력해주세요.': 'Please enter a stacking quantity greater than the minimum quantity.',
    '스테이킹 수량을 최대 수량 보다 작게 입력해주세요.': 'Please enter a stacking quantity less than the maximum quantity.',
}

