<template>
  <div class="wrap">
    <div class="layout_topBottom">
      <div class="setbox_block">
        <div class="setbox_items">
          <div class="container">
            <div class="row_25">
              <ul class="set_list set_list-uesr">
                <li>
                  <span>{{ $t('common.id') }}</span><span class="user_info">{{ email }}</span>
                </li>
                <li>
                  <span>{{ $t('common.contact') }}</span><span class="user_info">{{ phone }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="setbox_contents">
        <div class="setbox_block">
          <div class="setbox_items">
            <div class="container">
              <div class="row_25">
                <ul class="set_list">
                  <li>
                    <router-link :to="{ name: 'MypageLoginHistory' }">
                      <span>{{ $t('common.loginHistory') }}</span><i class="icon-arrow-right"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'MypagePinReset' }">
                      <span>{{ $t('pin.reset') }}</span><i class="icon-arrow-right"></i>
                    </router-link>
                  </li>
                  <li v-if="!isOTPAuth">
                    <router-link :to="{ name: 'MypageOTPSetting' }">
                      <span>{{ $t('otp.register') }}</span><i class="icon-arrow-right"></i>
                    </router-link>
                  </li>
                  <li @click="openLanguagePopup">
                    <a role="button">
                      <span>{{ $t('common.language') }}</span><i class="icon-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="setbox_block">
          <div class="setbox_items">
            <div class="container">
              <div class="row_25">
                <ul class="set_list">
                  <li>
                    <span>OTP {{ isOTPAuth ? $t('otp.using') : $t('otp.notUsing') }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_logout_wrap">
        <div class="container">
          <div class="row_25">
            <button class="one_btn l_btn fill_btn_bk radius_l" @click="logoutCf">{{ $t('common.logout') }}</button>
          </div>
        </div>
      </div>
    </div>
    <LanguagePopup ref="PopupLanguage" @closePopup="closePopup" />
  </div>
</template>

<script>
import LanguagePopup from "@/components/mypage/LanguagePopup.vue";

export default {
  name: "MypageMain",
  components: {LanguagePopup},
  data() {
    return {
      email: '',
      isOTPAuth: '',
      phone: '',
      isLanguageOpen: ''
    }
  },

  props: ['setHeader'],

  created() {
    this.getUserProfile((result) => {
      if (result.success) {
        this.email = result.userProfile.email
        this.isOTPAuth = result.userProfile.isOTPAuth
        this.phone = result.userProfile.phone
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.setHeader('arrow', this.$t('common.mypage'))
  },

  methods: {
    closePopup() {
      this.isLanguageOpen = false
      this.setHeader('arrow', this.$t('common.mypage'))
    },

    openLanguagePopup() {
      this.isLanguageOpen = true
      this.$refs.PopupLanguage.showPopup()
    },

    logoutCf() {
      this.$root.$refs.BuffModal.showDialog(this.$t('popup.logout'), this.$t('common.cancel'), this.$t('common.ok'), () => {
        this.logout()
      }, () => {
      })
    },

    logout() {
      this.$get(this.$MYPAGE_LOGOUT, 'logout', true, (result) => {
        this.httpAlert('success', result)
        sessionStorage.clear()
        this.$router.push({name: 'Login'})
      }, (result) => {
        sessionStorage.clear()
        this.$router.push({name: 'Login'})
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>