<template>
  <div class="wrap" v-if="show">
    <div class="bg_overlay">
      <div class="layout_popup">
        <div class="layout_container">
          <div class="modal_wrap">
            <div class="modal_radius">
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/h_logo.svg" alt="buff">
                    </span>
                  <div class="content_area">
                    <p v-html="msg" class="ment"></p>
                  </div>
                </div>
              </div>
              <div class="modal_btn bg_primary">
                <div class="btn_table" v-show="textOk || textNo">
                  <div class="btn_col bg_gr">
                    <button @click="no" v-show="textNo">{{ textNo }}</button>
                  </div>
                  <div class="btn_col">
                    <button @click="ok" v-show="textOk">{{ textOk }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuffModal",

  data() {
    return {
      show: false,
      callback: null,
      cancel: null,
      textOk: '',
      textNo: '',
      msg: '',
      title: '',
      isChecked: true,
      color: '',
      onlyDisplay: true,
    }
  },

  methods: {
    showDialog(msg, textNo, textOk, callback, cancel, isChecked = true, onlyDisplay = true) {
      this.msg = msg
      this.textNo = textNo
      this.textOk = textOk
      this.callback = callback
      this.cancel = cancel
      this.isChecked = isChecked
      this.onlyDisplay = onlyDisplay

      this.show = true
    },

    close() {
      this.show = false
    },

    ok() {
      if (!this.isChecked) {
        this.notify('error', this.$t('message.checkForTransaction'))
        return
      }
      this.callback()

      if (this.onlyDisplay) {
        this.close()
      }
    },

    no() {
      this.close()
      this.cancel()
    }
  }
}
</script>

<style scoped>

</style>
