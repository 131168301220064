<template>
  <div class="wrap" v-if="history">
    <div class="header clear">
      <div class="title"><span>{{ $t('staking.detail') }}</span></div>
      <div class="right">
        <button @click="$router.back()"><i class="icon-close"></i></button>
      </div>
    </div>
    <div class="layout_topBottom" v-if="history&&historyDetail">
      <div class="container">
        <div class="pt-30 pb-30 row_25">
                    <span class="logo_area">
                        <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
          <span class="dp_title mt-20">{{ history.stakingPlanName }}</span>
          <span class="dp_state mt-10" v-if="history.stakingPlanType === 'FIX'">
            {{ Math.trunc(history.rewardPoint) }}% BUFF {{ $t('common.provide') }}
          </span>
          <span class="dp_state mt-10" v-if="history.stakingPlanType === 'EXTRA'">{{ $t('staking.extraReward') }}</span>
        </div>
        <div class="row_25">
          <table class="bbs__view bbs__view2 underline type_1">
            <colgroup>
              <col style="width: 8rem;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>{{ $t('common.period') }}</th>
              <td>
                {{ formatDate(history.stakingStartDate, 'date') }} ~ {{formatDate(history.stakingExpiryDate, 'date')}}
              </td>
            </tr>
            <tr>
              <th>{{ $t('staking.rewardPeriod') }}</th>
              <td>
                <span v-if="history.stakingPlanType === 'FIX'">Day {{ $t('common.provide') }}</span>
                <span v-if="history.stakingPlanType === 'EXTRA'">{{ $t('staking.afterStaking') }}</span>
              </td>
            </tr>
            <tr v-if="history.stakingRewardTicker === 'USDT' && history.stakingPlanType === 'EXTRA'">
              <th>{{ $t('staking.totalBalance') }}</th>
              <td>{{ numberWithCommas(setDecimal(history.rewardPoint, 1)) }} {{ $t('common.currency') }}</td>
            </tr>
            <tr>
              <th>{{ $t('staking.amount') }}</th>
              <td class="primary">
                <span>{{ numberWithCommasAndDecimal(setDecimal(history.stakingAmount, 10000)) }}</span>BUFF<br>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="underline type_1" v-if="history.description">
            <p class="info_desc_3 mt-30">
              {{ history.description }}
            </p>
          </div>
          <div class="underline type_1">
            <h4 class="h4 mt-30 mb-20">{{ $t('staking.history') }}</h4>
            <ul class="gallery type_2">
              <li v-for="(item, index) in historyDetail" :key="index">
                <div class="gallery__content gray">
                  <div class="gallery__title mb-14">
                    <div class="title lcp">
                      <span class="text">{{ $t('common.division') }}</span>
                    </div>
                    <div class="desc_area">
                      <div class="detail fill_btn_gr s_btn radius_s pl-15 pr-15"
                           :class="{ 'fill_btn_gr2': item.classification === 'DONE','fill_btn_pr': item.classification === 'PAID',
                                     'fill_btn_bk': item.classification === 'ADD'|| item.classification === 'REGISTER'}">
                        <span class="txt">{{ trans(item.classification) }}</span>
                      </div>
                    </div>
                  </div>
                  <table class="bbs__view underline type_1">
                    <colgroup>
                      <col style="width: 7rem;">
                      <col>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>{{ $t('common.createdAt') }}</th>
                      <td>{{ formatDate(item.createdDate) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('common.updatedAt') }}</th>
                      <td>
                        <span v-if="item.updatedDate == null">-</span>
                        <span v-else>{{ formatDate(item.updatedDate) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('common.amount') }}</th>
                      <td><span>{{ numberWithCommasAndDecimal(setDecimal(item.amount, 10000)) }}</span>BUFF</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StakingHistoryDetail",

  data() {
    return {
      idx: this.$route.params.idx,
      history: [],
      historyDetail: [],
    }
  },

  mounted() {
    this.getHistory()
    this.getHistoryDetail()
  },

  methods: {
    getDateDiff(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();
      const result = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      return result;
    },

    trans(value) {
      if (value === 'REGISTER' || value === 'ADD') {
        return this.$t('common.staking')
      } else if (value === 'CLOSE' || value === 'SUBTRACT' || value === 'TERMINATE') {
        return this.$t('state.close')
      } else if (value === 'DONE') {
        return this.$t('state.done')
      } else if (value === 'PAID') {
        return this.$t('state.reward')
      }
    },

    getHistory() {
      this.$get(`${this.$STAKING_HISTORY}/${this.idx}`, 'get_history_detail', true, (result) => {
        this.history = result.data[0]
      }, (result) => {
        this.httpError(result)
      })
    },

    getHistoryDetail() {
      this.$get(`${this.$STAKING_HISTORY_DETAILS}?stakingIdx=${this.idx}`, 'get_history_detail', true, (result) => {
        this.historyDetail = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>