<template>
  <div class="wrap">
    <div class="onepage intro layout_bottom">
      <div class="onepage_flex flex flex_item_center">
        <div class="logo">
          <img src="/assets/image/intro.svg" alt="buff">
        </div>
      </div>
      <div class="onepage_b_area flex_end">
        <div class="container">
          <div class="row_25">
            <div class="text_field">
              <div class="basic_input">
                <input type="email" v-model="email" :placeholder="$t('input.id')" />
              </div>
              <div class="basic_input">
                <input type="password" v-model="password" :placeholder="$t('input.password')" @keydown.enter="login">
              </div>
            </div>
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="login">{{ $t('common.login') }}</button>
            </div>
            <div class="base_area">
              <div class="find_wrap">
                <a role="button" @click="popupOpen(0)">{{ $t('common.findId') }}</a>
                <a role="button" @click="popupOpen(1)">{{ $t('common.findPassword') }}</a>
              </div>
              <div class="find_wrap pt-10">
                <a role="button" @click="popupOpen(2)">{{ $t('common.snsLogin') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popupLogin @closeLoginPopup="openLoginPopup=false" :openLoginPopup="openLoginPopup"
                :popupType="popupType"/>
  </div>
</template>

<script>
import popupLogin from "@/components/popup/popupLogin";

export default {
  name: "VueLogin",
  components: {
    popupLogin
  },
  data() {
    return {
      email: '',
      password: '',
      openLoginPopup: false,
      popupType: '',
      loginData: ''
    }
  },

  props: ['showHeader'],

  created() {
    sessionStorage.clear()
  },

  mounted() {
    this.showHeader(false)
  },

  beforeUnmount() {
    this.showHeader(true)
  },

  methods: {
    popupOpen(type) {
      this.popupType = type
      this.openLoginPopup = true
    },
    login() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.password)

      this.$post(this.$USER_LOGIN, 'postLogin', formData, false, (result) => {
        let data = result.data[0]
        sessionStorage.setItem('authType', result.data[0].authType)
        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)
        this.$router.push({name: 'Pin', params: {isPinSet: data.isPinSet ? 2 : 0, authKey: data.authKey}})
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>