<template>
  <div class="wrap">
    <AddPopup ref="AddPopup" @closePopup=closePopup :planDetail="planDetail" :btnType="btnType"></AddPopup>
    <SubtractPopup ref="SubtractPopup" @closePopup=closePopup :planDetail="planDetail"
                   :btnType="btnType"></SubtractPopup>
    <div class="header clear">
      <div class="title"><span>{{ $t('staking.detail') }}</span></div>
      <div class="right">
        <button type="button" @click="$router.back()"><i class="icon-close"></i></button>
      </div>
    </div>
    <div class="layout_topBottom" v-if="planDetail">
      <div class="container">
        <div class="pt-30  pb-30 row_25">
                    <span class="logo_area">
                        <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
          <span class="dp_title mt-20">{{ planDetail.stakingPlanName }}</span>
          <span class="dp_state mt-10" v-if="planDetail.stakingPlanType === 'FIX'">
            {{ Math.trunc(planDetail.rewardPoint) }}% BUFF {{ $t('common.provide') }}
          </span>
          <span class="dp_state mt-10" v-if="planDetail.stakingPlanType === 'EXTRA'">{{ $t('staking.extraReward') }}</span>
        </div>
        <div class="row_25">
          <table class="bbs__view bbs__view2 underline type_1">
            <colgroup>
              <col style="width: 8rem;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>{{ $t('common.period') }}</th>
              <td>{{ formatDate(planDetail.stakingStartDate, 'date') }} ~
                {{ formatDate(planDetail.stakingExpiryDate, 'date') }}
              </td>
            </tr>
            <tr v-if="planDetail.stakingPlanType === 'EXTRA' && listType === 'PLAN'">
              <th>{{ $t('staking.period') }}</th>
              <td>
                {{ formatDate(planDetail.participateStartDate, 'date') }} ~
                {{ formatDate(planDetail.participateEndDate, 'date') }}
                <em class="comment">{{ $t('staking.periodLimit') }}</em></td>
            </tr>
            <tr v-if="planDetail.stakingPlanType === 'FIX' || listType === 'MY'">
              <th>{{ $t('staking.rewardPeriod') }}</th>
              <td>
                <span v-if="planDetail.stakingPlanType === 'FIX'">Day {{ $t('common.provide') }}</span>
                <span v-if="planDetail.stakingPlanType === 'EXTRA'">{{ $t('staking.afterStaking') }}</span>
              </td>
            </tr>
            <tr v-if="planDetail.stakingRewardTicker === 'BUFF'">
              <th>{{ $t('staking.paymentMethod') }}</th>
              <td>{{ $t('staking.coinAmount') }}</td>
            </tr>
            <tr v-if="planDetail.stakingRewardTicker === 'USDT' && parseInt(planDetail.rewardPoint) > 0">
              <th>{{ $t('staking.totalBalance') }}</th>
              <td>{{ numberWithCommas(setDecimal(parseInt(planDetail.rewardPoint), 1)) }} {{ $t('common.currency')}}</td>
            </tr>
            <tr>
              <th>{{ $t('staking.amount') }}</th>
              <td>
                <span>{{ numberWithCommasAndDecimal(setDecimal(parseFloat(planDetail.stakingAmount), 10000)) }}</span>BUFF<br>
              </td>
            </tr>
            <tr v-if="planDetail.stakingPlanType === 'EXTRA'">
              <th>{{ $t('staking.termination') }}</th>
              <td>{{ $t('staking.terminationLimit') }}</td>
            </tr>
            </tbody>
          </table>
          <p class="info_desc_2 t-center w-100 mb-30" v-if="planDetail.stakingPlanType === 'FIX'">
            <span v-if="listType === 'PLAN'">{{ $t('staking.description.staking') }}</span>
            <span v-if="listType === 'MY'">{{ $t('staking.description.termination') }}</span>
          </p>
          <div class="underline type_1" v-if="planDetail.description">
            <p class="info_desc_3 mt-30">
              {{ planDetail.description }}
            </p>
          </div>
          <div class="mt-20">
            <button class="fill_btn_pr l_btn radius_l one_btn mb-20" @click="openAddPopup('add')"
                    v-if="today !== formatDate(planDetail.stakingExpiryDate, 'date') && planDetail.stakingPlanType === 'FIX'">
              {{ $t('staking.add') }}
            </button>
            <button class="fill_btn_pr l_btn radius_l one_btn mb-20" @click="openAddPopup('add')"
                    v-if="today <= formatDate(planDetail.participateEndDate, 'date') && planDetail.stakingPlanType === 'EXTRA'">
              {{ $t('staking.add') }}
            </button>
            <button class="fill_btn_pr l_btn radius_l one_btn" @click="openSubtractPopup('subtract')">{{ $t('staking.stakingTermination') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddPopup from "@/components/staking/stakingPopup/AddPopup";
import SubtractPopup from "@/components/staking/stakingPopup/SubtractPopup";

export default {
  name: "StakingMyDetail",

  components: {
    AddPopup,
    SubtractPopup
  },

  data() {
    return {
      isPopupOpen: false,
      idx: this.$route.params.idx,
      listType: this.$route.params.listType,
      planDetail: '',
      isOTPAuth: false,
      btnType: '',
      today: '',
    }
  },

  mounted() {
    console.log(this.idx)
    this.getPlanDetail()
    this.getUserInfo()
  },

  methods: {
    getDateDiff(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();
      const result = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      return result;
    },

    getUserInfo() {
      this.getUserProfile((result) => {
        this.isOTPAuth = result.userProfile.isOTPAuth
      })
    },

    getPlanDetail() {
      this.$get(`${this.$STAKING_MY}/${this.idx}`, 'get_my_staking_detail', true, (result) => {
        this.planDetail = result.data[0]
        let d = new Date()
        this.today = this.dateFormat(d)
      }, (result) => {
        this.httpError(result)
      })
    },

    closePopup() {
      this.isPopupOpen = false
    },

    openSubtractPopup(btnType) {
      this.btnType = btnType
      if (this.isOTPAuth) {
        this.isPopupOpen = true
        this.$refs.SubtractPopup.showPopup()
      } else {
        this.$router.push({name: 'MypageOTPSetting'})
        this.$notify({type: 'error', text: this.$t('message.stakingOtp')})
      }
    },

    openAddPopup(btnType) {
      this.btnType = btnType
      if (this.isOTPAuth) {
        this.isPopupOpen = true
        this.$refs.AddPopup.showPopup()
      } else {
        this.$router.push({name: 'MypageOTPSetting'})
        this.$notify({type: 'error', text: this.$t('message.stakingOtp')})
      }
    },
  }
}
</script>

<style scoped>

</style>