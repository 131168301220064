<template>
  <div class="wrap" v-if="show">
    <StakingTerms ref="StakingTerms"></StakingTerms>
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container remove" @click="closePu">
          <div class="modal_wrap">
            <div class="modal_radius">
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
                  <div class="content_area">
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>{{ $t('staking.availableQuantity') }}</h4>
                      </div>
                      <div class="basic_input unit_input unit_input2 disabled">
                        <input type="text" id="number" :value="availableBalance" disabled>
                        <span class="unit">BUFF</span>
                      </div>
                    </div>
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>{{ $t('staking.applyQuantity') }}</h4>
                      </div>
                      <div class="basic_input unit_input unit_input3">
                        <input type="number" :placeholder="$t('input.amount')" v-model.number="amount" @input="handleInput"
                               inputmode="decimal">
                        <span class="unit">BUFF</span>
                      </div>
                      <div class="mt-10">
                        <ul class="persent">
                          <li class="cell" :class="{'selected':rate===10}">
                            <button class="cell_btn" @click="setPercent(10)">10%</button>
                          </li>
                          <li class="cell" :class="{'selected':rate===25}">
                            <button class="cell_btn" @click="setPercent(25)">25%</button>
                          </li>
                          <li class="cell" :class="{'selected':rate===50}">
                            <button class="cell_btn" @click="setPercent(50)">50%</button>
                          </li>
                          <li class="cell" :class="{'selected':rate===100}">
                            <button class="cell_btn" @click="setPercent(100)">100%</button>
                          </li>
                        </ul>
                      </div>
                      <div class="group mt-10">
                        <div class="m_input_tit">
                          <h4>{{ $t('otp.otpAuth') }}</h4>
                        </div>
                        <div class="basic_input btn_input_1">
                          <input type="text" :placeholder="$t('input.otpCode')" v-model="otpCode" inputmode="numeric"/>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <div class="checks_type2">
                        <input type="checkbox" id="agree1" v-model="termsAgree">
                        <label for="agree1" v-if="locale === 'ko'">
                          <span style="text-decoration:underline; font-weight: 500;" @click="openTerms">{{ $t('staking.termsAgreeLine') }}</span>{{ $t('staking.termsAgree') }}
                        </label>
                        <label for="agree1" v-else>
                          {{ $t('staking.termsAgree') }}<span style="text-decoration:underline; font-weight: 500;" @click="openTerms">{{ $t('staking.termsAgreeLine') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col">
                    <button type="button" @click="stakingCf">{{ $t('staking.register') }}</button>
                  </div>
                </div>
              </div>
              <div class="modal_close">
                <button type="button" @click="closePopup"><i class="icon-close"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StakingTerms from "@/components/staking/StakingTerms";

export default {
  name: "PlanPopup",

  components: {
    StakingTerms,
  },

  props: [
    'planDetail',
    'btnType'
  ],

  data() {
    return {
      stakingInfo: [],
      show: false,
      isTermsOpen: false,
      availableBalance: 0,
      rate: '',
      termsAgree: false,
      otpCode: '',
      idx: this.$route.params.idx,
      myBalance: 0,
      amount: '',
      locale: localStorage.getItem('locale')
    }
  },

  mounted() {
    this.getMyBalance()
  },

  methods: {
    handleInput(e) {
      let stringValue = e.target.value.toString()
      let regex = /^\d*(\.\d{1,4})?$/
      if (!stringValue.match(regex) && this.amount !== '') {
        this.amount = this.setDecimal(this.amount, 10000)
      }
    },

    stakingCf() {
      this.$root.$refs.BuffModal.showDialog(this.$t('popup.applyStaking'), this.$t('common.cancel'), this.$t('common.apply'), () => {
        this.goCheckPin()
      }, () => {
      })
    },

    goCheckPin() {
      if (!this.termsAgree) {
        this.$notify({type: 'error', text: this.$t('message.confirmTerms')})
        return
      }
      if (!this.otpCode) {
        this.$notify({type: 'error', text: this.$t('message.enterOtp')})
        return
      }
      if (parseFloat(this.availableBalance) < parseFloat(this.amount)) {
        this.$notify({type: 'error', text: `${this.$t('message.applyQuantity') + this.availableBalance}BUFF${this.t('message.is')}`})
        return
      }
      if (this.amount <= 0) {
        this.$notify({type: 'error', text: this.$t('message.positiveStaking')})
        return
      }
      if (parseFloat(this.planDetail.minQuantity) > parseFloat(this.amount)) {
        let minQuantity = this.setDecimal(parseFloat(this.planDetail.minQuantity), 10000).toFixed(4)
        this.$notify({type: 'error', text: `${this.$t('message.applyStakingQuantityMin') + minQuantity}BUFF${this.t('message.is')}`})
        return
      }
      if (parseFloat(this.planDetail.maxQuantity) < parseFloat(this.amount)) {
        let maxQuantity = this.setDecimal(parseFloat(this.planDetail.maxQuantity), 10000).toFixed(4)
        this.$notify({type: 'error', text: `${this.$t('message.applyStakingQuantityMax') + maxQuantity}${this.t('message.is')}`})
        return
      }
      if (this.idx && this.amount && this.otpCode) {
        this.$router.push({
          name: 'Pin',
          params: {
            planIdx: this.idx,
            amount: this.amount,
            otpCode: this.otpCode,
            isPinSet: 2,
            btnType: this.btnType
          }
        })
      } else {
        this.$notify({type: 'error', text: this.$t('message.inputNull')})
      }
    },

    setPercent(rate) {
      this.amount = this.setDecimal((this.availableBalance * rate / 100), 10000).toFixed(4)
      this.rate = rate
    },

    getMyBalance() {
      this.$get(this.$MAIN_MYBALANCE_AMOUNT, 'get_my_balance', true, (result) => {
        this.myBalance = this.setDecimal(parseFloat(result.data[0].availableBalance), 10000).toFixed(4)
        this.amount = 0
        let maxQuantity = this.setDecimal(parseFloat(this.planDetail.maxQuantity), 10000).toFixed(4)
        if (parseInt(this.myBalance) > parseInt(maxQuantity)) {
          this.availableBalance = maxQuantity
        } else {
          this.availableBalance = this.myBalance
        }
      }, (result) => {
        this.httpError(result)
      })
    },

    openTerms() {
      this.isTermsOpen = true
      this.$refs.StakingTerms.showPopup()
    },

    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true
      this.getMyBalance()
    },

  },

  watch: {
    amount() {
      if (this.amount !== this.setDecimal((this.availableBalance * this.rate / 100), 10000).toFixed(4)) {
        this.rate = ''
      }
    },
  }
}
</script>

<style scoped>

</style>