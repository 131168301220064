<template>
  <div class="wrap">
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">
            <div class="tit_area">
              <h2 class="title">{{ $t('common.resetPassword') }}</h2>
            </div>
            <div class="content_area">
              <div class="text_field group">
                <div class="basic_input" v-show="!verifyCode">
                  <input type="password" v-model="password" :placeholder="$t('input.currentPassword')">
                </div>
                <div class="basic_input">
                  <input type="password" v-model="newPassword" :placeholder="$t('input.newPassword')">
                </div>
                <div class="basic_input">
                  <input type="password" v-model="newPasswordConfirm" :placeholder="$t('input.checkPassword')">
                </div>
              </div>
              <div class="group">
                <p class="info_desc">{{ $t('common.passwordRegex') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l"
                      @click="verifyCode ? resetPassword() : changePassword()">{{ $t('common.changePassword') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordReset",

  data() {
    return {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
      verifyCode: '',
    }
  },

  props: ['setHeader'],

  created() {
    let hash = window.location.hash
    this.verifyCode = hash.split('=')[1]
  },

  mounted() {
    this.setHeader('arrow')
  },

  methods: {
    resetPassword() {
      if (!this.newPassword || !this.newPasswordConfirm) {
        this.$notify({type: 'error', 'text': this.$t('message.inputNull')})
        return
      }

      if (this.newPassword !== this.newPasswordConfirm) {
        this.$notify({type: 'error', 'text': this.$t('message.checkPassword')})
        return
      }

      let formData = new FormData()
      formData.append('newPassword', this.newPassword)
      formData.append('verifyCode', this.verifyCode)

      this.$post(this.$MYPAGE_PASSWORD_FIND, 'postMypagePasswordFind', formData, false, (result) => {
        this.$notify({type: 'success', 'text': result.message})
        this.$router.push({name: 'Login'})
      }, (result) => {
        this.httpError(result)
      })
    },

    changePassword() {
      if (!this.password || !this.newPassword || !this.newPasswordConfirm) {
        this.$notify({type: 'error', 'text': this.$t('message.inputNull')})
        return
      }

      if (this.newPassword !== this.newPasswordConfirm) {
        this.$notify({type: 'error', 'text': this.$t('message.checkPassword')})
        return
      }

      let formData = new FormData()
      formData.append('password', this.password)
      formData.append('newPassword', this.newPassword)

      this.$post(this.$MYPAGE_PASSWORD_RESET, 'postMypagePasswordReset', formData, true, (result) => {
        this.$notify({type: 'success', 'text': result.message})
        this.$router.push({name: 'Login'})
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>