<template>
  <div class="wrap">
    <div class="header clear">
      <div class="right">
        <button type="button" @click="$router.back()"><i class="icon-close"></i></button>
      </div>
    </div>
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">
            <div class="tit_area">
              <h2 class="title t_pr">{{ $t('pin.reset') }}</h2>
            </div>
            <div class="conten t_area type2" v-if="authType === 'bunny'">
              <div class="group">
                <p class="info_desc_2">{{ $t('pin.resetPinText') }}</p>
              </div>
              <div class="text_field group">
                <div class="basic_input">
                  <input type="password" :placeholder="$t('input.inputPw')" v-model="password"/>
                </div>
              </div>
            </div>
            <div class="content_area type2" v-if="authType !== 'bunny'">
              <div class="group">
                <p class="info_desc_2">{{ $t('pin.pinSns') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="resetPin">{{ $t('common.ok') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PinFind",
  data() {
    return {
      password: '',
      authKey: this.$route.params.authKey,
      isPinSet: 0,
      authType: ''
    }
  },
  mounted() {
    this.authType = sessionStorage.getItem('authType')
  },
  methods: {
    resetPin() {
      if (this.authType === 'bunny') {
        let formData = new FormData
        formData.append('authKey', this.authKey)
        formData.append('password', this.password)
        this.$post(this.$USER_PIN_RESET, 'reset_pin', formData, false, () => {
          this.$router.push({name: 'Pin', params: {isPinSet: 0, authKey: this.authKey}})
        }, (result) => {
          this.httpError(result.data)
        })
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>

</style>