<template>
  <div class="wrap layout_fixed" style="z-index: 11111; position: fixed; background: white" v-if="show">
    <div class="header clear">
      <div class="left">
        <button type="button" @click="closeTermsPopup"><i class="icon-arrow-left"></i></button>
      </div>
      <div class="title"><span>{{ $t('staking.terms') }}</span></div>
    </div>
    <div class="layout_topBottom scrollBar">
      <div class="container">
        <div class="row_25">
          <div class="term">
            <div class="toastui-editor-contents" v-html="terms.contents"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StakingTerms",

  data() {
    return {
      show: false,
      termsType: 'STAKING',
      terms: [],
    }
  },

  methods: {
    getStakingTerms() {
      this.$get(`${this.$TERMS}/${this.termsType}`, 'get_staking_terms', true, (result) => {
        this.terms = result.data[0]
      }, (result) => {
        this.httpError(result)
      })
    },

    closeTermsPopup() {
      this.show = false
      this.$emit('closeTermsPopup')
    },

    showPopup() {
      this.show = true
      this.getStakingTerms()
    },
  }
}
</script>

<style scoped>
.scrollBar {
  margin-left: 8px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrollBar::-webkit-scrollbar {
  width: 5px;
}

.scrollBar::-webkit-scrollbar-thumb {
  height: 30%;
  background: rgb(206, 204, 204);

  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
  background: rgba(107, 107, 107, 0.1);
}
</style>