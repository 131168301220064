export const languageKo = {
    common: {
        ko: '한국어',
        en: '영어',
        ok: '확인',
        cancel: '취소',
        apply: '신청',
        add: '추가',
        termination: '해지',
        undo: '지우기',
        login: '로그인',
        logout: '로그아웃',
        snsLogin: 'SNS 로그인 사용자',
        findId: '아이디 찾기',
        findPassword: '비밀번호 찾기',
        lockup: '락업',
        staking: '스테이킹',
        history: '내역',
        myBalance: '내 보유자산',
        totalBalance: '총 보유자산',
        deposit: '입금',
        withdraw: '출금',
        daily: '일간',
        monthly: '월간',
        paymentHistory: '입출금현황',
        paymentAll: '입출금 전체',
        all: '전체',
        selectPayment: '입출금 선택',
        selectYear: '연도 선택',
        select: '선택',
        mypage: '마이페이지',
        id: '아이디',
        contact: '연락처',
        loginHistory: '로그인 기록',
        date: '날짜',
        browser: '브라우저',
        checkPassword: '비밀번호 확인',
        none: '진행중인 내역이 없습니다.',
        paymentNone: '입출금 내역이 없습니다.',
        list: '목록',
        depositAddress: 'BUFF 입금주소',
        addressCopy: '주소복사',
        more: '더보기',
        close: '닫기',
        internalFee: '내부출금 수수료',
        externalFee: '외부출금 수수료',
        addressTo: '받는주소',
        withdrawAmount: '출금수량',
        goWithdraw: '출금하기',
        availableBalance: '출금가능수량',
        goDetail: '자세히보기',
        division: '구분',
        createdAt: '신청일',
        updatedAt: '지급일',
        amount: '수량',
        period: '기간',
        provide: '지급',
        currency: 'USD',
        auth: '인증',
        resetPassword: '비밀번호 재설정',
        changePassword: '비밀번호 변경',
        passwordRegex: '8~16자 영문 대 소문자, 숫자, 특수문자 사용',
        max: '최대',
        min: '최소',
        copy: '복사되었습니다.',
        language: '언어 설정',
        day: '일',
        days: '일'
    },
    state: {
        depositComplete: '입금 완료',
        withdrawComplete: '출금 완료',
        suspend: '중도해지',
        part: '부분해지',
        cancel: '종료',
        paid: '지급',
        processing: '지급중',
        done: '종료',
        terminate: '중도해지',
        close: '중도해지',
        reward: '보상',
        deposit: {
            pending: '입금 대기중',
            done: '입금 완료',
            error: '입금 에러',
            processing: '입금 진행중',
            declined: '입금 거절'
        },
        withdraw: {
            pending: '출금 대기중',
            done: '출금 완료',
            error: '출금 에러',
            processing: '출금 진행중',
            declined: '출금 거절'
        }
    },
    lockup: {
        myLockup: 'MY 락업',
        amount: '락업수량',
        period: '락업기간',
        reward: '락업보상',
        rewardAmount: '보상수량',
        ratio: '지급비율',
        detail: '락업 상세',
        history: '락업 내역',
    },
    staking: {
        myStaking: 'MY 스테이킹',
        period: '참여기간',
        fixedInterest: '고정이자',
        amount: '참여수량',
        cancelAmount: '참여수량',
        rewardAmount: '보상수량',
        totalBalance: '총보상금액',
        terms: '스테이킹 이용약관',
        detail: '스테이킹 상세',
        history: '스테이킹 내역',
        extraReward: '추가보상',
        rewardPeriod: '보상주기',
        paymentMethod: '지급방법',
        coinAmount: '코인수량',
        termination: '해지',
        afterStaking: '스테이킹 종료 후',
        periodLimit: '참여기간이 지난 경우 해당 스테이킹 \n참여가 불가능 합니다.',
        terminationLimit: '스테이킹 해지 시 보상이 \n지급되지 않습니다.',
        description: {
            staking: '스테이킹을 통해 디지털 자산을 \n정책에 따라 보상으로 받아보세요.',
            termination: '스테이킹 해지 시 해지 당일로부터 \n보상이 지급되지 않습니다.'
        },
        add: '스테이킹 추가',
        register: '스테이킹 신청',
        stakingTermination: '스테이킹 해지',
        able: '가능',
        unable: '불가능',
        registered: '스테이킹 신청이 완료되었습니다.',
        added: '스테이킹 추가가 완료되었습니다.',
        terminated: '스테이킹 해지가 완료되었습니다.',
        availableQuantity: '신청가능 수량',
        applyQuantity: '신청수량',
        addQuantity: '추가수량',
        terminationQuantity: '해지수량',
        termsAgreeLine: '스테이킹 이용약관',
        termsAgree: '을 모두 읽고 확인하였습니다.',
        terminationAgree: '스테이킹 해지에 동의합니다.',
        duplication: '중복참여'
    },
    pin: {
        enterText: '핀 번호를 입력해주세요.',
        setText: '핀 번호를 설정해주세요.',
        findText: 'PIN 번호를 잊어버렸어요.',
        reset: 'PIN 재설정',
        currentNumber: '현재 PIN 6자리',
        newNumber: '새로운 PIN 6자리',
        checkNewNumber: '새로운 PIN 확인',
        changePinText: 'PIN 변경을 위해 비밀번호를 입력하세요.',
        resetPinText: 'PIN 재설정을 위해 계정 비밀번호를 입력하세요.',
        resetPin: 'PIN 변경',
        pinSns: '버니프렌즈를 SNS로 가입하신 경우, PIN 재설정을 위해 관리자에게 문의해 주세요.',
        oneMore: '핀 번호를 한 번 더 입력해주세요.',
        incorrect: '핀 번호가 다릅니다.',
        returnStart: '처음부터 다시 시도해 주세요.'
    },
    otp: {
        useOTP: 'OTP 사용중',
        otpAuth: 'OTP 인증',
        register: 'OTP 등록',
        using: '사용중',
        notUsing: '미사용중',
        android: '안드로이드',
        ios: '아이폰',
        playStore: '플레이스토어 OTP',
        appStore: '앱스토어 OTP',
        googleNewUser: 'Google OTP 앱 신규 사용자',
        googleUser: 'Google OTP 앱 기존 사용자',
        step1: '1단계 OTP앱 다운로드',
        step2: '2단계 QR코드 인증',
        step3: '3단계 OTP 인증',
        code: '코드',
        description: {
            register: '입출금시 휴대폰 인증 번호 대신 OTP 인증번호를 사용하게 됩니다.',
            step1: '시간 동기 (Time OTP)방식을 사용하는 OTP 인증 앱을 스마트폰에 다운로드합니다.',
            step2: '2단계 인증 모바일 앱을 실행하여 아래의 QR코드를 스캔하세요.',
            step2_2: '만약 모바일 앱이 QR코드를 지원하지 않는다면, 아래 코드를 직접 입력하세요.',
            step3: 'QR코드 스캔 후 OTP에 표시되는 코드를 입력하세요.',
            android: '플레이스토어에서 검색하세요.',
            ios: '앱스토어에서 검색하세요.',
        },
    },
    input: {
        id: '아이디를 입력해 주세요.',
        password: '비밀번호를 입력해 주세요.',
        address: '주소 입력',
        amount: '수량 입력',
        otpCode: '인증 코드',
        code: '코드 입력',
        currentPassword: '현재 비밀번호',
        newPassword: '새로운 비밀번호',
        checkPassword: '비밀번호 확인',
        inputPw: '비밀번호 입력'
    },
    popup: {
        findId: 'BUFF 락업 지갑은 버니프렌즈에 등록된 \n핸드폰 번호로 로그인이 가능합니다.',
        findPassword: '비밀번호는 버니프렌즈 앱을 통해 \n비밀번호 찾기가 가능합니다.',
        snsLogin: '버니프렌즈를 SNS로 가입하신 경우 \n버니프렌즈 앱에서만 \n지갑 서비스를 이용하실 수 있습니다.',
        withdraw: '코인 수량, 주소가 정확한지 확인하세요. 블록체인 특성상 잘못된 주소로 입금을 할 경우 회수가 어렵습니다.',
        logout: '로그아웃 하시겠습니까?',
        addStaking: '스테이킹을 추가 하시겠습니까?',
        applyStaking: '스테이킹을 신청 하시겠습니까?',
        terminateStaking: '스테이킹을 해지 하시겠습니까?',
    },
    message: {
        code: '코드를 입력해주세요.',
        inputNull: '빈 칸을 입력해주세요.',
        checkPassword: '새 비밀번호가 일치하지 않습니다.',
        checkPin: '새 핀이 일치하지 않습니다.',
        stakingOtp: '스테이킹 신청은 OTP 등록이 완료되어야 가능합니다.',
        insufficientBalance: '잔고가 부족합니다.',
        positiveNumber: '출금가능한 금액은 양수만 입력 가능합니다.',
        positiveStaking: '스테이킹 금액은 양수만 입력 가능합니다.',
        confirmTerms: '스테이킹 이용약관을 확인해 주세요.',
        enterOtp: 'OTP코드를 입력해 주세요.',
        additionalQuantity: '추가가능한 수량은 ',
        stakingQuantity: '스테이킹 가능한 추가수량은 최대 ',
        applyQuantity: '신청가능한 수량은 ',
        applyStakingQuantityMin: '스테이킹 참여수량은 최소 ',
        applyStakingQuantityMax: '스테이킹 참여수량은 최대 ',
        terminateQuantity: '해지가능한 수량은 최대 ',
        terminateStakingQuantity: '스테이킹 해지수량은 최소 ',
        is: ' 입니다.',
        terminateStaking: '스테이킹 해지에 동의해 주세요.'
    },
}