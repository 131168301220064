<template>
  <div class="wrap" v-show="openLoginPopup">
    <div class="bg_overlay">
      <div class="layout_popup">
        <div class="layout_container">
          <div class="modal_wrap modal_wrap_fix">
            <div class="modal_radius modal_radius_fix">
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/h_logo.svg" alt="buff">
                    </span>
                  <div class="content_area">
                    <p class="ment" v-show="popupType===0">{{ $t('popup.findId') }}</p>
                    <p class="ment" v-show="popupType===1">{{ $t('popup.findPassword') }}</p>
                    <p class="ment" v-show="popupType===2">{{ $t('popup.snsLogin') }}</p>
                  </div>
                </div>
              </div>
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col">
                    <button type="button" @click="$emit('closeLoginPopup')">{{ $t('common.ok') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginPopup",

  props: [
    'openLoginPopup',
    'popupType',
  ],
}
</script>

<style scoped>

</style>