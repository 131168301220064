import {createApp} from 'vue'
import {router} from './router/index'
import App from './App.vue'
import '@fortawesome/fontawesome-free/js/all.js'
import Notifications from '@kyvg/vue3-notification'
import axios from "axios"
import { createI18n } from 'vue-i18n'
import messages from './resource/i18n'
import common from "@/resource/common";

function setLocale() {
  const locale = localStorage.getItem("locale")
  if (locale) {
    return locale
  } else {
    const locale = (navigator.language || navigator.language).substring(0, 2)
    if(locale === 'ko' || locale === 'en'){
      localStorage.setItem('locale', locale)
      return locale
    } else {
      localStorage.setItem('locale', 'en')
      return 'en'
    }
  }
}

const i18n = createI18n({
  locale: setLocale(), // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

const app = createApp(App)

common(app.config.globalProperties)
app.use(i18n)
app.use(router)
app.use(Notifications)

router.isReady().then(() => {
  app.mount('#app')

})

let requestOption = () => {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 60000
  }
}
let requestOptionWithToken = () => {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
    },
    timeout: 60000
  }
}

app.config.globalProperties.getCoinLabel = () => {
  return process.env.VUE_APP_COIN_LABEL
}

let pending_get = {}
let pending_post = {}

app.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN
app.config.globalProperties.$USER_LOGIN = "/user/login"
app.config.globalProperties.$USER_BUNNY_LOGIN = "/user/bunny/login"
app.config.globalProperties.$USER_REFRESH_TOKEN = "/user/refresh/token"
app.config.globalProperties.$USER_PIN_AUTH = "/user/pin/auth"
app.config.globalProperties.$USER_PIN_INIT = "/user/pin/init"
app.config.globalProperties.$USER_PIN_RESET = "/user/pin/reset"
app.config.globalProperties.$MAIN_DECIMAL_VIEW = "/main/decimal/view"
app.config.globalProperties.$MAIN_MYBALANCE_AMOUNT = "/main/myBalance/amount"
app.config.globalProperties.$MAIN_BALANCE = "/main/balance"
app.config.globalProperties.$MAIN_WALLET_HISTORY = "/main/wallet/history"
app.config.globalProperties.$USER_PROFILE = "/user/profile"
app.config.globalProperties.$MAIN_WITHDRAW = "/main/withdraw"
app.config.globalProperties.$MAIN_LOCKUP_DETAIL = "/main/lockup/detail"
app.config.globalProperties.$MAIN_CURRENCY_RATE = "/main/currency/rate"
app.config.globalProperties.$MYPAGE_PASSWORD_RESET = "/mypage/password/reset"
app.config.globalProperties.$MYPAGE_PASSWORD_FIND = "/mypage/password/find"
app.config.globalProperties.$MYPAGE_LOGIN_HISTORY = "/mypage/login/history"
app.config.globalProperties.$MYPAGE_PIN_RESET = "/mypage/pin/reset"
app.config.globalProperties.$MYPAGE_OTP = "/mypage/otp"
app.config.globalProperties.$MYPAGE_OTP_AUTH = "/mypage/otp/auth"
app.config.globalProperties.$MYPAGE_LOGOUT = "/mypage/logout"
app.config.globalProperties.$LOCKUP_MY = "/lockup/my"
app.config.globalProperties.$LOCKUP_HISTORY = "/lockup/history"
app.config.globalProperties.$LOCKUP_HISTORY_LOGS = "/lockup/history/logs"
app.config.globalProperties.$STAKING_PLAN = "/staking/plan"
app.config.globalProperties.$STAKING_MY = "/staking/my"
app.config.globalProperties.$STAKING_ADD = "/staking/add"
app.config.globalProperties.$STAKING_REGISTER = "/staking/register"
app.config.globalProperties.$STAKING_SUBTRACT = "/staking/subtract"
app.config.globalProperties.$STAKING_HISTORY = "/staking/history"
app.config.globalProperties.$STAKING_HISTORY_DETAILS = "/staking/history/details"
app.config.globalProperties.$TERMS = "/terms"
app.config.globalProperties.$MAIN_CHART = "/main/currency/rate/chart"

app.config.globalProperties.$post = async function (callUrl, caller, postData, useToken, success, fail) {
  console.log(arguments[0])
  if (pending_post[arguments[0] + caller]) {
    return
  }
  pending_post[arguments[0] + caller] = true
  let _requestOption = requestOption()
  if (useToken) {
    let token = sessionStorage.getItem('accessToken')
    if (token && token.length > 0) {
      _requestOption = requestOptionWithToken()
    } else {
      this.$router.replace({name: 'Login'})
    }
  }
  axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
    console.log(response)
    pending_post[arguments[0] + caller] = false
    if (!response.data.result) {
      this.$notify({type: 'error', text: response.data.message})
      return
    }
    success(response.data)
  }).catch(e => {
    pending_post[arguments[0] + caller] = false
    console.log(e)
    fail(e.response.data)

    if (e.response.data.status === 403 || e.response.data.status === 401) {
      this.$router.replace({name: 'Login'})
      sessionStorage.removeItem('accessToken')
      this.$store.state.isLoggedIn = false
    }

  })
}

app.config.globalProperties.$get = async function (callUrl, caller, useToken, success, fail) {
  console.log(arguments[0])
  if (pending_get[arguments[0] + caller]) {
    return
  }
  pending_get[arguments[0] + caller] = true
  let _requestOption = requestOption()
  if (useToken) {
    let token = sessionStorage.getItem('accessToken')
    if (token && token.length > 0) {
      _requestOption = requestOptionWithToken()
    } else {
      this.$router.replace({name: 'Login'})
    }
  }
  axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
    pending_get[arguments[0] + caller] = false
    if (!response.data.result) {
      this.$notify({type: 'error', text: response.data.message})
      return
    }
    success(response.data)
  }).catch(e => {
    pending_get[arguments[0] + caller] = false
    fail(e.response.data)
    if (e.response.data.status === 403 || e.response.data.status === 401) {
      this.$router.replace({name: 'Login'})
      sessionStorage.removeItem('accessToken')
      this.$store.state.isLoggedIn = false
    }
  })
}

//------------------------------------------------------------------------------------------
