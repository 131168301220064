<template>
  <div class="wrap">
    <div class="header clear">
      <div class="left" @click="$router.back()">
        <button><i class="icon-arrow-left"></i></button>
      </div>
    </div>
    <div class="layout_topBottom">
      <div class="container">
        <div class="row_25">
          <div class="tit_group">
            <h2 class="title">{{ $t('otp.register') }}</h2>
            <p class="tit_desc">{{ $t('otp.description.register') }}</p>
          </div>
          <hr class="hr_lg">
          <div class="content_group">
            <div class="step_tit">
              <span class="tit">{{ $t('otp.step1') }}</span>
              <p class="desc">
                {{ $t('otp.description.step1') }}
              </p>
            </div>
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">{{ $t('otp.android') }}</span>
                <p class="desc">{{ $t('otp.description.android') }}</p>
              </div>
              <div class="step_item_block">
                <span class="img_otp"><em class="hide">{{ $t('otp.playStore') }}</em></span>
              </div>
              <div class="step_item_block">
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko"
                   target="_blank" class="one_btn outline_btn radius_l l_btn t-center pt-10">
                  {{ $t('otp.playStore') }}
                </a>
              </div>
            </div>
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">{{ $t('otp.ios') }}</span>
                <p class="desc">{{ $t('otp.description.ios') }}</p>
              </div>
              <div class="step_item_block">
                <span class="img_otp"><em class="hide">{{ $t('otp.appStore') }}</em></span>
              </div>
              <div class="step_item_block">
                <a href="https://apps.apple.com/kr/app/google-authenticator/id388497605"
                   target="_blank" class="one_btn outline_btn radius_l l_btn t-center pt-10">
                  {{ $t('otp.appStore') }}
                </a>
              </div>
            </div>
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">{{ $t('otp.googleNewUser') }}</span>
              </div>
              <div class="step_item_block">
                <div class="google_img">
                  <span class="google_new_otp" :class="{ 'img_en_new' : locale === 'en' }"><em class="hide">{{ $t('otp.appStore') }}</em></span>
                </div>
              </div>
            </div>
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">{{ $t('otp.googleUser') }}</span>
              </div>
              <div class="step_item_block">
                <div class="google_img">
                  <span class="google_otp" :class="{ 'img_en' : locale === 'en' }"><em class="hide">{{ $t('otp.appStore') }}</em></span>
                </div>
              </div>
            </div>
          </div>
          <hr class="hr_lg">
          <div class="content_group">
            <div class="step_tit">
              <span class="tit">{{ $t('otp.step2') }}</span>
              <p class="desc">
                {{ $t('otp.description.step2') }}
              </p>
            </div>
            <div class="step_items">
              <div class="step_item_block">
                <div class="qr_zone">
                  <qrcode-vue class="qr_padding" :value="otpUrl" :size="size" level="H"/>
                </div>
              </div>
              <div class="step_item_block">
                <div class="step_descript">
                  <p class="desc">{{ $t('otp.description.step2_2') }}</p>
                  <span class="code">{{ $t('otp.code') }} : <b>{{ otpKey }}</b></span>
                </div>
              </div>
            </div>
          </div>
          <hr class="hr_lg">
          <div class="content_group">
            <div class="step_tit">
              <span class="tit">{{ $t('otp.step3') }}</span>
              <p class="desc">
                {{ $t('otp.description.step3') }}
                <em class="ex_code">BUFF ({{ email }})</em>
              </p>
            </div>
            <div class="step_items">
              <div class="step_item_block">
                <div class="basic_input btn_input_2">
                  <input type="number" :placeholder="$t('otp.input.code')" v-model="otpCode"/>
                  <button class="l_btn radius_l fill_btn_bk" @click="verify">{{ $t('common.auth') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "MypageOTPSetting",

  data() {
    return {
      otpKey: '',
      otpUrl: '',
      size: 200,
      email: '',
      otpCode: '',
      locale: ''
    }
  },

  components: {
    QrcodeVue,
  },

  created() {
    this.locale = localStorage.getItem('locale')
    this.$get(this.$MYPAGE_OTP, 'getMypageOTP', true, (result) => {
      this.otpKey = result.data[0].otpKey
      this.otpUrl = result.data[0].otpUrl
    }, (result) => {
      this.httpError(result)
    })

    this.getUserProfile((result) => {
      if (result.success) {
        this.email = result.userProfile.email
      } else {
        console.log(result.userProfile)
        this.httpError(result)
      }
    })
  },

  methods: {
    verify() {
      if (!this.otpCode) {
        this.$notify({type: 'error', text: this.$t('message.code')})
        return
      }

      let formData = new FormData()
      formData.append('otpCode', this.otpCode)
      formData.append('key', this.otpKey)

      this.$post(this.$MYPAGE_OTP_AUTH, 'mypageOTPAuth', formData, true, (result) => {
        this.httpAlert('success', result)
        this.$router.back()
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>
.img_en_new {
    background: url(/public/assets/image/google_otp_en_new.png) no-repeat center/ 100% auto;
}
.img_en {
    background: url(/public/assets/image/google_otp_en.png) no-repeat center/ 100% auto;
}
</style>