<template>
  <div class="wrap" v-if="history">
    <div class="header clear">
      <div class="title"><span>{{ $t('lockup.detail') }}</span></div>
      <div class="right">
        <button @click="$router.back()"><i class="icon-close"></i></button>
      </div>
    </div>
    <div class="layout_topBottom">
      <div class="container">
        <div class="pt-30 pb-30 row_25">
          <span class="logo_area">
              <img src="/assets/image/ico_buff.svg" alt="BUFF">
          </span>
          <span class="dp_title mt-20">{{ history.lockupPlanName }}</span>
        </div>
        <div class="row_25">
          <table class="bbs__view bbs__view2 underline type_1">
            <colgroup>
              <col style="width: 8rem;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>{{ $t('lockup.period') }}</th>
              <td>
                {{ formatDate(history.lockupStartDate, 'date') }} ~ {{ formatDate(history.lockupExpiryDate, 'date') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('lockup.amount') }}</th>
              <td>
                <span>{{ numberWithCommasAndDecimal(setDecimal(history.lockupAmount, 10000)) }}</span>BUFF<br>
              </td>
            </tr>
            <tr>
              <th>{{ $t('lockup.rewardAmount') }}</th>
              <td>
                <span>{{ numberWithCommasAndDecimal(setDecimal(history.rewardAmount, 10000)) }}</span>BUFF<br>
              </td>
            </tr>
            <tr>
              <th>{{ $t('lockup.reward') }}</th>
              <td class="primary">
                <span>{{ numberWithCommas(setDecimal(history.rewardRate, 10)) }}</span>%<br>
              </td>
            </tr>
            <tr>
              <th>{{ $t('lockup.ratio') }}</th>
              <td class="primary">
                <span>{{ numberWithCommas(setDecimal(100 / history.lockupRatio, 10)) }}</span>%<br>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="underline type_1">
            <h4 class="h4 mt-30 mb-20">{{ $t('lockup.history') }}</h4>
            <ul class="gallery type_2">
              <li v-for="(item, index) in historyDetail" :key="index">
                <div class="gallery__content gray">
                  <div class="gallery__title mb-14">
                    <div class="title lcp">
                      <span class="text">{{ $t('common.division') }}</span>
                    </div>
                    <div class="desc_area">
                      <div class="detail fill_btn_gr s_btn radius_s pl-15 pr-15"
                           :class="{ 'fill_btn_gr2': item.lockUpHistoryType === 'CANCEL','fill_btn_pr': item.lockUpHistoryType === 'PAID',
                                     'fill_btn_bk': item.lockUpHistoryType === 'REGISTER'}">
                        <span class="txt">{{ trans(item.lockUpHistoryType) }}</span>
                      </div>
                    </div>
                  </div>
                  <table class="bbs__view underline type_1">
                    <colgroup>
                      <col style="width: 7rem;">
                      <col>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>{{ $t('common.createdAt') }}</th>
                      <td>{{ formatDate(item.createdAt) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('common.updatedAt') }}</th>
                      <td>
                        <span v-if="item.updatedAt == null">-</span>
                        <span v-else>{{ formatDate(item.updatedAt) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('common.amount') }}</th>
                      <td><span>{{ numberWithCommasAndDecimal(setDecimal(item.amount, 10000)) }}</span>BUFF</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LockupHistory",

  data() {
    return {
      idx: this.$route.params.idx,
      history: [],
      historyDetail: [],
      page: 1,
      limit: 10,
    }
  },

  mounted() {
    this.getHistory()
    this.getHistoryDetail()
  },

  methods: {
    trans(value) {
      if (value === 'REGISTER') {
        return this.$t('common.lockup')
      } else if (value === 'SUSPEND') {
        return this.$t('state.suspend')
      } else if (value === 'PART') {
        return this.$t('state.part')
      } else if (value === 'CANCEL') {
        return this.$t('state.cancel')
      } else if (value === 'PAID') {
        return this.$t('state.paid')
      }
    },

    getHistory() {
      this.$get(`${this.$LOCKUP_HISTORY}/${this.idx}`, 'get_lu_history', true, (result) => {
        this.history = result.data[0]
      }, (result) => {
        this.httpError(result)
      })
    },

    getHistoryDetail() {
      this.$get(`${this.$LOCKUP_HISTORY_LOGS}/${this.idx}?page=${this.page}&limit=${this.limit}`, 'get_lu_history_detail', true, (result) => {
        this.historyDetail = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>