<template>
  <div class="wrap" v-if="show">
    <div class="bg_overlay">
      <div class="toast_popup remove" @click="closePu">
        <div class="toast_wrap">
          <div class="toast_container">
            <div class="toast_row">
              <div class="ts_tit">
                <h2 class="title t_pr">{{ $t('common.language') }}</h2>
              </div>
              <hr class="hr_lg_10">
              <div class="toast_content">
                <ul class="list_desc">
                  <li @click="setLanguage('ko')">
                    <p class="align_between">
                      <span class="dsec_dt">{{ $t('common.ko') }}</span>
                    </p>
                  </li>
                  <li @click="setLanguage('en')">
                    <p class="align_between">
                      <span class="dsec_dt">{{ $t('common.en') }}</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div class="modal_close">
                <button type="button" @click="closePopup"><i class="icon-close"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguagePopup",

  data() {
    return {
      show: false
    }
  },

  methods: {
    setLanguage(locale) {
      localStorage.setItem('locale', locale)
      this.$i18n.locale = locale
      this.closePopup()
    },
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true
    }
  }
}
</script>

<style scoped>

</style>