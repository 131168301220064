<template>
  <div class="wrap" v-if="show">
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container remove" @click="closePu">
          <div class="modal_wrap modal_wrap_fix">
            <div class="modal_radius modal_radius_fix">
              <div class="modal_content bg_wh">
                <div class="madal_row">
                  <span class="logo_area">
                    <img src="/assets/image/ico_buff.svg" alt="BUFF">
                  </span>
                  <div v-show="qrScanner" class="content_area">
                    <div class="group">
                      <div v-show="qrScanner" id="webQR" class="qr-style"></div>
                    </div>
                    <button class="btn_qrcode" @click="closeQR()">{{ $t('common.close') }}</button>
                  </div>
                  <div v-show="!qrScanner" class="content_area">
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>{{ $t('common.addressTo') }}</h4>
                      </div>
                      <div class="basic_input unit_input">
                        <input type="text" :placeholder="$t('input.address')" v-model="withdrawInfo.addressTo"/>
                        <span class="unit" @click="openQR"><i class="icon-qr"></i></span>
                      </div>
                    </div>
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>{{ $t('common.withdrawAmount') }}</h4>
                        <p>
                          <span class="info_desc_1 info">
                            {{ $t('common.internalFee') }}
                            <em class="fee">{{ fee.internalFee }} <i
                                class="unit">{{ fee.internalFeeType ? '%' : getCoinLabel() }}</i></em>
                          </span><br/>
                          <span class="info_desc_1 info">
                            {{ $t('common.externalFee') }}
                            <em class="fee">{{ fee.externalFee }} <i
                                class="unit">{{ fee.externalFeeType ? '%' : getCoinLabel() }}</i></em>
                          </span>
                        </p>
                      </div>
                      <div class="basic_input unit_input">
                        <input type="number" min="0" :placeholder="$t('input.amount')" v-model="withdrawInfo.amount"
                               inputmode="decimal"/>
                        <span class="unit" @click="withdrawInfo.amount = availableBalance">MAX</span>
                      </div>
                      <p class="info_desc_1 mw_info clear">
                        <span class="left">{{ $t('common.availableBalance') }}</span>
                        <span class="right t_bold">{{ numberWithCommasAndDecimal(availableBalance) }} <i
                            class="unit t_bold">{{ getCoinLabel() }}</i></span>
                      </p>
                    </div>
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>{{ $t('otp.otpAuth') }}</h4>
                      </div>
                      <div class="basic_input btn_input_1">
                        <input type="number" :placeholder="$t('input.otpCode')" v-model="withdrawInfo.otpCode" inputmode="numeric"/>
                      </div>
                    </div>
                    <div class="group">
                      <p class="info_desc_2">
                        {{ $t('popup.withdraw') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal_btn bg_primary" @click="goCheckPin">
                <div class="btn_table">
                  <div class="btn_col">
                    <button>{{ $t('common.goWithdraw') }}</button>
                  </div>
                </div>
              </div>
              <div class="modal_close" @click="closePopup">
                <button><i class="icon-close"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Html5QrcodeScanner} from "html5-qrcode"

export default {
  name: "PopupWithdraw",

  data() {
    return {
      show: false,
      isOtpSet: false,
      withdrawInfo: {
        addressTo: '',
        amount: '',
        phoneCode: '',
        otpCode: ''
      },
      fee: {
        internalFee: '',
        internalFeeType: false,
        externalFee: '',
        externalFeeType: false
      },
      isOTPAuth: false,
      qrScanner: false,
      html5QrcodeScanner: null,
    }
  },

  props: [
    'availableBalance'
  ],

  mounted() {
    window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
  },

  beforeUnmount() {
    window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
  },

  methods: {
    onScanSuccess(decodedText) {
      this.setQr({
        detail: decodedText
      })

      this.closeQR()
    },

    openQR() {
      this.qrScanner = true

      if (this.html5QrcodeScanner !== null) {
        this.html5QrcodeScanner.clear()

        delete this.html5QrcodeScanner
      }

      this.html5QrcodeScanner = new Html5QrcodeScanner("webQR", {fps: 10, qrbox: {width: 250, height: 250}}, false);
      this.html5QrcodeScanner.render(this.onScanSuccess);

      this.openQRReader('QR스캔')
      this.setQRString()
      this.window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
    },
    
    closeQR() {
      if (this.html5QrcodeScanner !== null) {
        this.html5QrcodeScanner.clear()

        delete this.html5QrcodeScanner
      }
      this.qrScanner = false
    },

    setQr(e) {
      let qr = e.detail
      this.withdrawInfo.addressTo = qr
    },

    closePopup() {
      this.closeQR()

      this.show = false
      this.$emit('closePopup')

      this.withdrawInfo = {
        addressTo: '',
        amount: '',
        phoneCode: '',
        otpCode: ''
      }
    },

    showPopup() {
      this.show = true
      if (this.show) {
        this.$get(this.$MAIN_DECIMAL_VIEW, 'mainDecimalView', true, (result) => {
          this.fee = result.data[0]
        }, (result) => {
          console.log(result)
        })

        this.getUserProfile((result) => {
          if (result.success) {
            this.isOTPAuth = result.userProfile.isOTPAuth
          } else {
            console.log(result.userProfile)
          }
        })
      }
    },

    goCheckPin() {
      if (this.availableBalance < this.withdrawInfo.amount) {
        this.$notify({type: 'error', text: this.$t('message.insufficientBalance')})
        return
      }
      if (this.withdrawInfo.amount <= 0) {
        this.$notify({type: 'error', text: this.$t('message.positiveNumber')})
        return
      }

      if (this.withdrawInfo.addressTo && this.withdrawInfo.amount && (this.withdrawInfo.phoneCode || this.withdrawInfo.otpCode)) {
        this.$router.push({
          name: 'Pin',
          params: {
            addressTo: this.withdrawInfo.addressTo,
            amount: this.withdrawInfo.amount,
            phoneCode: this.withdrawInfo.phoneCode,
            otpCode: this.withdrawInfo.otpCode,
            isPinSet: 2
          }
        })
      } else {
        this.$notify({type: 'error', text: this.$t('message.inputNull')})
      }
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 390px) {
  .info_desc_1 {
    font-size: 1.2rem;
  }
}

.btn_qrcode {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
}
</style>