<template>
  <div class="wrap" v-if="show">
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container remove" @click="closePu">
          <div class="modal_wrap modal_wrap_fix">
            <div class="modal_radius modal_radius_fix">
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
                  <div class="content_area">
                    <div class="de_tit group">
                      <span class="t_pr">{{ $t('common.depositAddress') }}</span>
                      <p>{{ address }}</p>
                    </div>
                    <div class="qr_zone group">
                      <qrcode-vue :value="address" :size="size" level="H"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col" @click="copyAddress">
                    <button>{{ $t('common.addressCopy') }}</button>
                  </div>
                </div>
              </div>
              <div class="modal_close" @click="closePopup">
                <button><i class="icon-close"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "popupDeposit",

  data() {
    return {
      address: '',
      name: '',
      email: '',
      show: false,
      size: 100,
    }
  },

  components: {
    QrcodeVue,
  },

  methods: {
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true

      if (this.show) {
        this.getUserProfile((result) => {
          if (result.success) {
            this.address = result.userProfile.address
            this.name = result.userProfile.name
            this.email = result.userProfile.email
          } else {
            console.log(result.userProfile)
          }
        })
      }
    },

    copyAddress() {
      let tempInput = document.createElement('input')
      tempInput.value = this.address
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.$notify({type: 'success', text: this.$t('common.copy')})
    },
  }
}
</script>

<style scoped>

</style>