export const languageEn = {
    common: {
        ko: 'Korean',
        en: 'English',
        ok: 'OK',
        cancel: 'Cancel',
        apply: 'Apply',
        add: 'Add',
        termination: 'Termination',
        undo: 'Undo',
        login: 'Login',
        logout: 'Logout',
        snsLogin: 'SNS Login',
        findId: 'Find ID',
        findPassword: 'Find Password',
        lockup: 'Lockup',
        staking: 'Staking',
        history: 'History',
        myBalance: 'My Balance',
        totalBalance: 'Total Balance',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        daily: 'Daily',
        monthly: 'Monthly',
        paymentHistory: 'Payment History',
        paymentAll: 'All Payment',
        all: 'All',
        selectPayment: 'Select Payment',
        selectYear: 'Select Year',
        select: 'Select',
        mypage: 'My Page',
        id: 'ID',
        contact: 'Contact',
        loginHistory: 'Login History',
        date: 'Date',
        browser: 'Browser',
        checkPassword: 'Check Password',
        none: 'No ongoing history.',
        paymentNone: 'No payment history.',
        list: 'List',
        depositAddress: 'BUFF Deposit Address',
        addressCopy: 'Copy Address',
        more: 'More',
        close: 'Close',
        internalFee: 'Internal Withdrawal Fee',
        externalFee: 'External Withdrawal Fee',
        addressTo: 'Recipient Address',
        withdrawAmount: 'Withdrawal Amount',
        goWithdraw: 'Withdraw',
        availableBalance: 'Available Balance',
        goDetail: 'See Details',
        division: 'Division',
        createdAt: 'Application date',
        updatedAt: 'payment date',
        amount: 'Amount',
        period: 'Period',
        provide: 'Provide',
        currency: 'Won',
        auth: 'Auth',
        resetPassword: 'Reset Password',
        changePassword: 'Change Password',
        passwordRegex: 'Use 8-16 characters with a combination of uppercase, lowercase, numbers, and special characters',
        max: 'Maximum',
        min: 'Minimum',
        copy: 'Copied.',
        language: 'Language',
        day: 'DAY',
        days: 'DAYS'
    },
    state: {
        depositComplete: 'Deposit Complete',
        withdrawComplete: 'Withdrawal Complete',
        suspend: 'Suspended',
        part: 'Partial Termination',
        cancel: 'End',
        paid: 'Paid',
        processing: 'In Progress',
        done: 'End',
        terminate: 'Terminated',
        close: 'Terminated',
        reward: 'Reward',
        deposit: {
            pending: 'Deposit Pending',
            done: 'Deposit Completed',
            error: 'Deposit Error',
            processing: 'Deposit in Progress',
            declined: 'Deposit Declined'
        },
        withdraw: {
            pending: 'Withdrawal Pending',
            done: 'Withdrawal Completed',
            error: 'Withdrawal Error',
            processing: 'Withdrawal in Progress',
            declined: 'Withdrawal Declined'
        }
    },
    lockup: {
        myLockup: 'MY Lockup',
        amount: 'Lockup Amount',
        period: 'Lockup Period',
        reward: 'Lockup Reward',
        rewardAmount: 'Reward Amount',
        ratio: 'Payment Ratio',
        detail: 'Lockup Details',
        history: 'Lockup History',
    },
    staking: {
        myStaking: 'MY Staking',
        period: 'Participation Period',
        fixedInterest: 'Fixed Interest',
        amount: 'Participation Amount',
        cancelAmount: 'Cancellation Amount',
        rewardAmount: 'Reward Amount',
        totalBalance: 'Total Reward Amount',
        terms: 'Staking Terms',
        detail: 'Staking Details',
        history: 'Staking History',
        extraReward: 'Extra Reward',
        rewardPeriod: 'Reward Cycle',
        paymentMethod: 'Payment Method',
        coinAmount: 'Coin Amount',
        termination: 'Termination',
        afterStaking: 'After Staking Ends',
        periodLimit: 'You cannot participate in this staking after the participation period.',
        terminationLimit: 'No rewards will be paid upon staking termination.',
        description: {
            staking: 'Through staking, receive digital assets as rewards according to the policy.',
            termination: 'When staking termination, rewards will not be paid from the day of staking termination.'
        },
        add: 'Add Staking',
        register: 'Register Staking',
        stakingTermination: 'Staking Termination',
        able: 'Available',
        unable: 'Unavailable',
        registered: 'Staking registration has been completed.',
        added: 'Staking addition has been completed.',
        terminated: 'Staking termination has been completed.',
        availableQuantity: 'The available quantity for application',
        applyQuantity: 'The quantity to apply for',
        addQuantity: 'The additional quantity',
        terminationQuantity: 'Termination quantity',
        termsAgreeLine: 'the staking agreement.',
        termsAgree: 'I have read and agree to all the terms and conditions of ',
        terminationAgree: 'Agree to staking termination.',
        duplication: 'Duplicate participation'
    },
    pin: {
        enterText: 'Please enter your PIN number.',
        setText: 'Please set your PIN number.',
        findText: 'I forgot my PIN number.',
        reset: 'Reset PIN',
        currentNumber: 'Current 6-digit PIN',
        newNumber: 'New 6-digit PIN',
        checkNewNumber: 'Confirm New PIN',
        changePinText: 'Please enter your password to change your PIN.',
        resetPinText: 'Please enter your account password to reset your PIN.',
        resetPin: 'Reset PIN',
        pinSns: 'If you signed up for BunnyFriends with SNS, please contact the administrator to reset your PIN.',
        oneMore: 'Please enter your PIN number one more time.',
        incorrect: 'The PIN numbers do not match.',
        returnStart: 'Please try again from the beginning.'
    },
    otp: {
        useOTP: 'Using OTP',
        otpAuth: 'OTP Authentication',
        register: 'Register OTP',
        using: 'In Use',
        notUsing: 'Not In Use',
        android: 'Android',
        ios: 'iOS',
        playStore: 'Play Store OTP',
        appStore: 'App Store OTP',
        googleNewUser: 'Google OTP App New User',
        googleUser: 'Google OTP App Existing User',
        step1: 'Step 1: Download OTP app',
        step2: 'Step 2: QR code authentication',
        step3: 'Step 3: OTP authentication',
        code: 'Code',
        description: {
            register: 'Use OTP authentication instead of mobile authentication when depositing and withdrawing.',
            step1: 'Download an OTP authentication app that uses time synchronization (Time OTP) to your phone.',
            step2: 'Run the 2-step authentication mobile app and scan the QR code below.',
            step2_2: 'If your mobile app does not support QR codes, manually enter the code below.',
            step3: 'After scanning the QR code, enter the code displayed on the OTP.',
            android: 'Search on Play Store.',
            ios: 'Search on App Store.'
        },
    },
    input: {
        id: 'Please enter your ID.',
        password: 'Please enter your password.',
        address: 'Enter the address',
        amount: 'Enter the amount',
        otpCode: 'OTP code',
        code: 'Enter the code',
        currentPassword: 'Current password',
        newPassword: 'New password',
        checkPassword: 'Confirm password',
        inputPw: 'Enter your password'
    },
    popup: {
        findId: 'BUFF Lockup Wallet allows login with the phone number registered in BunnyFriends.',
        findPassword: 'You can reset your password through the BunnyFriends app.',
        snsLogin: 'If you signed up for BunnyFriends with SNS, you can only use the wallet service in the BunnyFriends app.',
        withdraw: 'Please double-check the coin amount and address. Once deposited to the wrong address, recovery may be difficult due to the nature of blockchain.',
        logout: 'Do you want to log out?',
        addStaking: 'Would you like to add to your staking?',
        applyStaking: 'Would you like to apply for staking?',
        terminateStaking: 'Would you like to terminate the staking?',
    },
    message: {
        code: 'Please enter the code.',
        inputNull: 'Please fill in the blank.',
        checkPassword: 'The new password does not match.',
        checkPin: 'The new PIN does not match.',
        stakingOtp: 'To apply for staking, OTP registration must be completed.',
        insufficientBalance: 'Insufficient balance.',
        positiveNumber: 'Only positive numbers are allowed for withdrawal.',
        positiveStaking: 'Staking amount can only be entered as a positive value.',
        confirmTerms: 'Please confirm the Staking Terms and Conditions.',
        enterOtp: 'Please enter the OTP code.',
        additionalQuantity: 'The additional available quantity is ',
        stakingQuantity: 'The maximum additional quantity available for staking is ',
        applyQuantity: 'The available quantity for application is ',
        applyStakingQuantityMin: 'The minimum staking participation amount is ',
        applyStakingQuantityMax: 'The maximum staking participation amount is ',
        terminateQuantity: 'The maximum quantity available for termination is ',
        terminateStakingQuantity: 'The minimum quantity for staking termination is ',
        is: '.',
        terminateStaking: 'Please agree to the staking termination.'
    }
}