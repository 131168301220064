<template>
  <div class="wrap">
    <LockupPopup ref="LockupPopup" @closePopup=closePopup :lockupDetail="lockupDetail"></LockupPopup>
    <PopupSelectYear ref="PopupSelectYear" @closePopup=closePopup :year="year" @change="changeYear"
                     @selectYear="selectYear"></PopupSelectYear>
    <div style="position: fixed; width: 100%; background: white; z-index: 9">
      <div class="tablist pt-50">
        <ul class="tab_type1">
          <li class="tab_type1-item" :class="{'selected':listType === 'MY'}">
            <a role="button" class="item_link" @click="tabBtn('MY')">{{ $t('lockup.myLockup') }}</a>
          </li>
          <li class="tab_type1-item" :class="{'selected':listType === 'HISTORY'}">
            <a role="button" class="item_link" @click="tabBtn('HISTORY')">{{ $t('common.history') }}</a>
          </li>
        </ul>
      </div>
      <div class="year_box" v-if="listType==='HISTORY'">
        <button class="year_btn" @click="openSelectYear">{{ this.year }}</button>
      </div>
    </div>
    <div class="contents h102 scroll_list" v-if="listType==='MY'" @scroll="handleHistoryListScroll">
      <div class="container pt-110">
        <div class="row_25">
          <div class="none_content" v-if="myLockupList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>{{ $t('common.none') }}</p>
          </div>
          <ul class="gallery pt-30 pb-90" v-else>
            <li v-for="(list, index) in myLockupList" :key="index">
              <div class="gallery__content opacity">
                <div class="gallery__title mb-14">
                  <div class="img_box">
                    <img src="/assets/image/@gallery.png" alt="">
                  </div>
                  <div class="title">
                    <span class="text white">{{ list.lockupPlanName }}</span>
                  </div>
                  <div class="desc_area">
                    <a role="button" class="detail fill_btn_bk s_btn radius_s pl-15 pr-15"
                       @click="openLockupPopup(list)">
                      <span class="txt">{{ $t('common.goDetail') }}</span>
                    </a>
                  </div>
                </div>
                <table class="bbs__view underline type_2">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>{{ $t('lockup.amount') }}</th>
                    <td><span>{{ numberWithCommasAndDecimal(setDecimal(list.lockupAmount, 10000)) }}</span>BUFF</td>
                  </tr>
                  <tr>
                    <th>{{ $t('lockup.period') }}</th>
                    <td>
                      {{ formatDate(list.lockupStartDate, 'date') }} ~ {{ formatDate(list.lockupExpiryDate, 'date') }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('lockup.ratio') }}</th>
                    <td><span>{{ 100 / list.lockupRatio }}</span>%</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="contents h154 scroll_list" v-if="listType==='HISTORY'" @scroll="handleHistoryListScroll">
      <div class="container pt-160">
        <div class="row_25">
          <div class="none_content" v-if="luHistoryList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>{{ $t('common.none') }}</p>
          </div>
          <ul class="gallery pt-30 pb-100" v-else>
            <li v-for="(list, index) in luHistoryList" :key="index"
                @click="$router.push({ name: 'LockupHistory', params: { idx: list.lockupIdx } })">
              <h3 class="gallery__month mb-20" v-if="!setDate(index)">
                {{ formatDate(list.lockupStartDate, 'month') }}
              </h3>
              <div class="gallery__content opacity">
                <div class="gallery__title mb-14">
                  <div class="img_box">
                    <img src="/assets/image/@gallery.png" alt="">
                  </div>
                  <div class="title">
                    <span class="text white">{{ list.lockupPlanName }}</span>
                  </div>
                  <div class="desc_area">
                    <span class="status type2"
                          :class="{'type1' : list.status === 'SUSPEND', 'type3': list.status === 'PROCESSING'}">
                      {{ trans(list.status) }}
                    </span>
                  </div>
                </div>
                <table class="bbs__view underline type_2">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>{{ $t('lockup.amount') }}</th>
                    <td><span>{{ numberWithCommasAndDecimal(setDecimal(list.lockupAmount, 10000)) }}</span>BUFF</td>
                  </tr>
                  <tr>
                    <th>{{ $t('lockup.rewardAmount') }}</th>
                    <td><span>{{ numberWithCommasAndDecimal(setDecimal(list.rewardAmount, 10000)) }}</span>BUFF</td>
                  </tr>
                  <tr>
                    <th>{{ $t('lockup.reward') }}</th>
                    <td><span>{{ parseFloat(list.rewardRate) }}</span>%</td>
                  </tr>
                  <tr>
                    <th>{{ $t('lockup.ratio') }}</th>
                    <td><span>{{ 100 / list.lockupRatio }}</span>%</td>
                  </tr>
                  <tr>
                    <th>{{ $t('lockup.period') }}</th>
                    <td>
                      {{ formatDate(list.lockupStartDate, 'date') }} ~ {{ formatDate(list.lockupExpiryDate, 'date') }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fixed_container">
      <div class="sw_button_area">
        <div class="sw_button">
          <div class="btn_table">
            <div class="btn_col" @click="$router.replace({ name : 'LockupList' })">
              <button class="addStatus"><span class="sw_btn btn1">{{ $t('common.lockup') }}</span></button>
            </div>
            <div class="btn_col" @click="$router.replace({ name : 'StakingList' })">
              <button class="addStatus"><span class="sw_btn btn2">{{ $t('common.staking') }}</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LockupPopup from "@/components/lockup/LockupPopup";
import PopupSelectYear from "@/components/popup/PopupSelectYear";

export default {
  name: "LockupList",

  components: {
    LockupPopup,
    PopupSelectYear,
  },

  props: ['setHeader'],

  data() {
    return {
      isPopupOpen: false,
      isSelectYearOpen: false,
      listType: this.$route.query.listType ? this.$route.query.listType : 'MY',
      page: 1,
      limit: 5,
      myLockupList: [],
      luHistoryList: [],
      year: '',
      lockupDetail: []
    }
  },

  mounted() {
    this.setHeader('dashboard', this.$t('common.lockup'))
    let d = new Date()
    this.year = d.getFullYear()
    if (this.listType === 'MY') {
      this.getMyLockupList()
    } else {
      this.getLUHistoryList()
    }
  },

  methods: {
    tabBtn(type) {
      this.page = 1
      if (type === this.listType) {
        return
      }
      if (type === 'MY') {
        this.getMyLockupList()
      } else {
        this.getLUHistoryList()
      }
    },

    handleHistoryListScroll(e) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight;
      if (isAtTheBottom) this.handleLoadMore();
    },

    handleLoadMore() {
      if (this.listType === 'MY') {
        if (this.myLockupList.length < this.total) {
          this.page = this.page + 1
          this.getMyLockupList()
        }
      } else {
        if (this.luHistoryList.length < this.total) {
          this.page = this.page + 1
          this.getLUHistoryList()
        }
      }
    },

    changeYear(year) {
      this.year = year
    },

    queryChange() {
      this.$router.push({
        query: {listType: this.listType},
      });
    },

    setDate(index) {
      let date = this.formatDate(this.luHistoryList.lockupStartDate, 'month')
      index--
      if (index == -1) {
        return false
      }
      let date2 = this.formatDate(this.luHistoryList.lockupStartDate, 'month')
      let result = date == date2
      return result
    },

    trans(value) {
      if (value === 'SUSPEND') {
        return this.$t('state.suspend')
      } else if (value === 'DONE') {
        return this.$t('state.done')
      } else if (value === 'CANCEL') {
        return this.$t('state.cancel')
      } else if (value === 'PROCESSING') {
        return this.$t('state.processing')
      }
    },

    getLUHistoryList() {
      if (this.page === 1) {
        this.myLockupList = []
      }
      this.listType = 'HISTORY'
      let formData = new FormData()
      formData.append('year', this.year)
      formData.append('page', this.page)
      formData.append('limit', this.limit)
      this.$get(`${this.$LOCKUP_HISTORY}?${new URLSearchParams(formData).toString()}`, 'get_my_lockup', true, (result) => {
        this.total = result.total
        for (let i = 0; i < result.data.length; i++) {
          this.luHistoryList.push(result.data[i])
        }
        this.queryChange()
      }, (result) => {
        this.httpError(result)
      })
    },

    getMyLockupList() {
      if (this.page === 1) {
        this.luHistoryList = []
      }
      let d = new Date()
      this.year = d.getFullYear()
      this.listType = 'MY'
      let formData = new FormData()
      formData.append('page', this.page)
      formData.append('limit', this.limit)
      this.$get(`${this.$LOCKUP_MY}?${new URLSearchParams(formData).toString()}`, 'get_my_lockup', true, (result) => {
        this.total = result.total
        for (let i = 0; i < result.data.length; i++) {
          this.myLockupList.push(result.data[i])
        }
        this.queryChange()
      }, (result) => {
        this.httpError(result)
      })
    },

    selectYear() {
      this.isPopupOpen = false
      this.isSelectYearOpen = false
      this.luHistoryList = []
      this.getLUHistoryList()
    },

    closePopup() {
      this.isPopupOpen = false
      this.isSelectYearOpen = false
    },

    openLockupPopup(list) {
      this.lockupDetail = []
      this.lockupDetail = list
      this.isPopupOpen = true
      this.$refs.LockupPopup.showPopup()
    },

    openSelectYear() {
      this.isSelectYearOpen = true
      this.$refs.PopupSelectYear.showPopup()
    },
  }
}
</script>

<style scoped>
.scroll_list {
  -ms-overflow-style: none;
}

.scroll_list::-webkit-scrollbar {
  display: none;
}

.scroll_list {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}

.none_content {
  height: calc(100vh - 154px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.none_content p {
  font-size: 16px;
  text-align: center;
  margin: 12px;
  color: white;
}
</style>